import Tippy from '@tippyjs/react';
import React from 'react';
import styled from 'styled-components';
import 'tippy.js/dist/tippy.css';
import useMedia from 'use-media';

import { Icon } from './Icon';
const StyledTooltip = styled(Tippy)`
  background-color: #2a374f;
  color: white;
  padding: 0.75rem;
  font-size: 0.85rem;
  line-height: 1.3rem;
  font-weight: normal;
  border-radius: 0.25rem;
`;
export const Tooltip = ({ icon, title, ...props }) => {
  const isMobile = useMedia({
    maxWidth: 440,
  });
  return (
    <StyledTooltip
      content={title}
      placement={isMobile ? 'bottom' : 'right-start'}
      interactive
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
            },
          },
        ],
      }}
      {...props}
    >
      <span>
        {React.isValidElement(icon) ? icon : <Icon icon={icon || 'question-circle'} color="#a4a7c6" />}
      </span>
    </StyledTooltip>
  );
};
